<script>
import SideBar from "@/pages/SideBar.vue";
import { VueSpinnerHourglass } from "vue3-spinners";
import { getSettings, saveSettings } from "@/api/orders";
import { mapActions } from "vuex";
   
export default {
  components: {
    SideBar,
    VueSpinnerHourglass
  },
  data() {
    return {
      loading: false,
      settings: [],
      port: "",
      ip: ""
    };
  },
  mounted() {
    this.getSettingsData()
  },
  methods: {
    ...mapActions("global", ["showError", "showSuccess"]),
    saveSettingsData() {
      this.loading = true;
      saveSettings({kds_configuration: {port: this.port, ip:this.ip}})
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.settings = response.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    getSettingsData() {
      this.loading = true;
      getSettings()
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.settings = response.data;
            if(this.settings) {
                this.port = this.settings.port,
                this.ip = this.settings.ip
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    }

  }
};
</script>
<template>
  <SideBar>
    <VueSpinnerHourglass
      size="100"
      color="blue"
      :class="'absolute top-50 left-50 hourgalss z-50'"
      v-show="loading"
    />
    <div
      class="fixed bottom-5 right-5 clear-both bg-blue-300 sm:w-2/6 md:1/6 lg:w-60 p-3 opacity-50"
    >
      <p class="px-4 text-left m-0 text-gray-500 text-sm leading-3">
        Create or Update Settings
      </p>
      <p class="text-left text-gray-500 text-sm leading-5">
        <small class="px-4 text-left m-0">show all settings for KDS</small>
      </p>
    </div>
    <div class="w-[70vw]">
      <p class="text-left px-3 font-bold my-3 text-[14pt]">Printer Settings</p>

      <div class="grid grid-cols-1 lg:grid-cols-2 px-3 gap-3 w-full">
        <div class="flex flex-col my-4 px-3 items-start">
          <label for="ip" class="text-[12pt]">IP Address</label>
          <input
            type="text"
            v-model="ip"
            placeholder="ip address"
            class="w-full text-[12pt] p-2 rounded border border-gray-500"
          />
        </div>
        <div class="flex flex-col my-4 px-3 items-start">
          <label for="port" class="text-[12pt]">Port Number</label>
          <input
            type="text"
            placeholder="port number"
            v-model="port"
            class="w-full text-[12pt] p-2 rounded border border-gray-500"
          />
        </div>
      </div>
      <div class="flex flex-row items-end justify-end w-full px-5">
        <button
          class="bg-blue-500 text-white px-4 py-2 rounded text-[12pt]"
          @click="saveSettingsData()"
        >
          Save
        </button>
      </div>
    </div>
  </SideBar>
</template>